import { action, makeObservable, observable } from "mobx";
import adminAPI from "../apis/adminAPI";
import { Subject } from "../enums/subjects";
import Device from "../interfaces/user/device";
import OrganizationBase from "../interfaces/user/organizationBase";
import Role from "../interfaces/user/role";
import UserDetails from "../interfaces/user/userDetails";
import { getUserPressesTypes } from "../services/adminService";

type AccessRole = "LandaLearningUser";
const supportedRoles: Array<AccessRole> = ["LandaLearningUser"];

export default class UserStore {
  @observable organizations?: OrganizationBase[];
  @observable userInfo?: UserDetails;
  @observable devices?: Device[];
  @observable devicesTypes?: Subject[];
  @observable roles?: Role[];
  @observable isAllowed?: boolean;

  constructor() {
    makeObservable(this);
  }
  @action
  setUserInfo = (userInfo: UserDetails) => {
    this.userInfo = userInfo;

    if (this.userInfo) {
      this.init();
    }
  };

  @action
  private setOrganizations = (organizations?: OrganizationBase[]) => {
    this.organizations = organizations;
  };

  @action
  setDevices(devices: Device[]) {
    this.devices = devices;
  }

  @action
  setDevicesTypes(types: Subject[]) {
    this.devicesTypes = types;
  }

  @action
  setRoles(roles: Role[]) {
    this.roles = roles;
  }

  @action setIsAllowed(roles: Role[]) {
    this.isAllowed = false;
    if (roles?.find((role) => supportedRoles.includes(role.name as AccessRole)))
      this.isAllowed = true;
  }

  private fetchOrganizations = async () => {
    const organizations = await adminAPI.fetchOrganizations();
    this.setOrganizations(organizations?.sort((a, b) => (a.name > b.name ? 1 : -1)));
  };

  private fetchDevices = async () => {
    const devices = await adminAPI.fetchDevices();
    this.setDevices(devices);
  };

  private fetchDevicesTypes = async () => {
    if (this.devices) this.setDevicesTypes(getUserPressesTypes(this.devices));
  };

  private fetchRoles = async () => {
    const roles = await adminAPI.fetchRoles();
    this.setRoles(roles);
    this.setIsAllowed(roles);
  };
  
  private init = async () => {
    await this.fetchRoles();
    await this.fetchOrganizations();
    await this.fetchDevices();
    await this.fetchDevicesTypes();
  };
}
