import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import App from './App';

WebFont.load({
  active: () => sessionStorage.fonts = true,
  inactive: async() => {
    console.error('fonts offline')
    
    await import('./styles/fonts/insightFonts')

    WebFont.load({
      custom: {
        families: ['Roboto:300,400,500,700', 'Oswald:200,300,400,500,600', 'Roboto Condensed:300,400,700'],
        urls: ["./insightFonts.css"],
      },
      inactive: () => console.error('load failed locally. no fonts available')
    })
  },

  google: {
    families: ['Roboto:300,400,500,700', 'Oswald:200,300,400,500,600', 'Roboto Condensed:300,400,500,600,700'],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
