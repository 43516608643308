import { action, makeObservable, observable } from "mobx";
import UserDetails from "../interfaces/user/userDetails";

export class AdministrationStore {
    @observable userInfo: UserDetails = {name:'',userName:''}

    constructor(){
        makeObservable(this)
    }
    
    @action
    setUserInfo = (userInfo : UserDetails) =>{
        this.userInfo = userInfo;
    }
    
}