import background from "../resources/background.svg";
import logo from "../resources/logo.svg";
import { makeStyles } from "@mui/styles";
import { Theme, Button } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflowY: "hidden",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    background: `url(${background}) ${theme.background.fillWhite}`,
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    transition: "background-size 1s ease-in",
    userSelect:'none',
    [theme.breakpoints.down("md")]: {
      backgroundSize: "200%"
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "400%"
    },
  },
  welcomeContainer: {
    flexDirection: "column",
    paddingTop: "10.9rem",
  },
  welcomeText: {
    textAlign:'center',
    fontFamily: "oswald",
    textTransform: "uppercase",
    fontSize: "6.25rem",
    color: theme.textColors.title,
    textShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
    fontWeight: 700,
    lineHeight: '9.25rem',
    transition: "0.3s",
    [theme.breakpoints.down("md")]: {
      fontSize: "5.31rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "4.68rem",
      lineHeight:'5.625rem',
      '-webkit-text-stroke-width':'1px',
      '-webkit-text-stroke-color':'white',
      marginBottom:'0.625rem'
    },
  },
  loginButton: {
    width: "14.375rem",
    fontFamily:'Roboto',
    fontWeight:400,
    color: theme.textColors.white,
    backgroundColor: theme.palette.primary.main,
    height: "3.125rem",
    lineHeight: "1.875rem",
    fontSize: "1.563rem",
    boxShadow: "0px 5px 10px rgba(0,0,0,0.45)",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    },
    [theme.breakpoints.down("md")]: {
      width: "12.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "10.313rem",
    },
  },
  logo: {
    position: "absolute",
    top: "2.5rem",
    left: "2.25rem",
    width: "11.563rem",
    transition: "0.3s",
    [theme.breakpoints.down("sm")]: {
      width: "9.375rem",
      top: "0.625rem",
      left: "0.625rem",
    },
  },
}));

export interface WelcomeProps {
  onSignIn: () => void;
}

const Welcome = (props: WelcomeProps) => {
  const { onSignIn } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div>
        <img src={logo} alt="logo" className={classes.logo} />
      </div>
      <div className={`${classes.container} ${classes.welcomeContainer}`}>
        <div className={classes.welcomeText}>landa learning</div>
        <div>
          <Button className={classes.loginButton} onClick={onSignIn}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
