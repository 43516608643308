import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Story } from "../../interfaces/story";
import Card from "./card";

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: "1.25rem",
    marginBottom: "2.5rem",
    background: theme.background.fillWhite,
    boxShadow: "0px 1px 10px 3px rgba(0, 0, 0, 0.1)",
  },
  header: {
    color: theme.textColors.title,
    fontSize: theme.fontSize.subTitle,
    textTransform: "uppercase",
    marginBottom: "1.2rem",
  },
  cardContainer: {
    display: "flex",
    gap: "1.625rem",
    rowGap: "1.25rem",
    flexWrap: "wrap",
    [theme.breakpoints.up(1250)]: {
      "& > *": {
        width: "23%",
      },
    },
    [theme.breakpoints.down(1250)]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
      "& > *": {
        width: "48%",
      },
    },
    [theme.breakpoints.down(730)]: {
        "& > *": {
          width: "100%",
        },
      },
  },
}));

interface SectionProps {
  header: string;
  stories: Story[];
}

const Section = (props: SectionProps) => {
  const { header, stories } = props;
  const classes = useStyles();

  const renderedCards = () => {
    return stories.map((story: Story) => {
      return <Card story={story} key={story.title}/>;
    });
  };
  return (
    <div className={classes.section}>
      <div className={classes.header}>{header}</div>
      <div className={classes.cardContainer}>{renderedCards()}</div>
    </div>
  );
};
export default Section;
