import { Subject } from "../enums/subjects";
import Device from "../interfaces/user/device";

type PressType = "d10" | "s10" | "w10";
export const getUserPressesTypes = (devices: Device[]) => {
  let userSubjects: Subject[] = [];
  for (let i = 0; i < devices.length; i++) {
    const deviceType = <PressType>devices[i].serial.slice(0, 3);
    switch (deviceType) {
      case "d10": {
        if (!userSubjects.includes(Subject.S10P)) userSubjects.push(Subject.S10P);
        break;
      }
      case "s10": {
        if (!userSubjects.includes(Subject.S10)) userSubjects.push(Subject.S10);
        break;
      }
      case "w10": {
        //add w10 subject!!! and if!!
        break;
      }
      default: {
        break;
      }
    }
  }
  userSubjects.push(Subject.General,Subject.PrePress);
  userSubjects.sort((a,b)=>a.toString()>b.toString()?1:(a.toString()===b.toString()?0:-1));
  return userSubjects;
};
