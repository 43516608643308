import { msalApp, GRAPH_REQUESTS, requiresInteraction, fetchWebApi } from "./authUtils";
import { InteractiveSignInRequired, LoginFailed, TokenRequestInHiddenIFrame } from "./authConfig";
import Identity from "../../models/identity";
import { ClientAuthError, WindowUtils, AuthenticationParameters } from "msal";
import { Mutex } from "async-mutex";
import telemetryService from "../telemetry/telemetryService";

class AuthService {
  mutex = new Mutex();
  private _identityCallback: ((identity: Identity) => void) | undefined;
  private _abortController: AbortController = new AbortController();

  async acquireToken(request: any) {
    if (WindowUtils.isInIframe()) throw new TokenRequestInHiddenIFrame();

    const release = await this.mutex.acquire();
    return msalApp
      .acquireTokenSilent(request)
      .catch((error) => {
        console.log("msal acquireTokenSilent err", error);
        if (requiresInteraction(error.errorCode)) {
          return msalApp.acquireTokenPopup(request);
        } else {
          throw new InteractiveSignInRequired();
        }
      })
      .finally(() => release());
  }

  private authCallback = (tokenReceivedCallback: any, errorReceivedCallback: any) => {
    const identity = new Identity(tokenReceivedCallback);
    if (this._identityCallback) this._identityCallback(identity);
  };

  handleIdentityCallback(callback: (identity: Identity) => void) {
    this._identityCallback = callback;
  }

  async signIn() {
    const loginResponse = await msalApp.loginPopup(GRAPH_REQUESTS.LOGIN).catch((error) => {
      if (error instanceof ClientAuthError && error.errorCode === "popup_window_error") {
        msalApp.handleRedirectCallback(this.authCallback);
        return msalApp.loginRedirect({
          ...GRAPH_REQUESTS.LOGIN,
          redirectUri: window.location.origin,
          prompt: "select_account",
        });
      } else {
        telemetryService.trackExceptionDataV2(error);
        throw new LoginFailed(error.message);
      }
    });

    if (loginResponse) {
      const identity = new Identity(loginResponse);
      if (this._identityCallback) this._identityCallback(identity);
      return;
    }
  }

  signOut() {
    msalApp.logout();
  }

  public getAccessToken = async ( scope: string) => {
    if (WindowUtils.isInIframe()) return;

    const accessTokenRequest: AuthenticationParameters = {
      scopes: [scope],
    };

    return await this.acquireToken(accessTokenRequest);
  };

  public request = async (url: string, scope: string, options: any) => {
    if (WindowUtils.isInIframe()) return;

    const accessTokenRequest: AuthenticationParameters = {
      scopes: [scope],
    };

    const token = await this.acquireToken(accessTokenRequest);

    return fetchWebApi(url, options, scope, token.accessToken, this._abortController.signal);
  };

  async getIdentity() {
    const account = msalApp.getAccount();
    if (account && !WindowUtils.isInIframe()) {
      return await msalApp
        .acquireTokenSilent(GRAPH_REQUESTS.LOGIN)
        .then((value) => new Identity(value))
        .catch((error) => {
          if (requiresInteraction(error.errorCode)) {
            throw new InteractiveSignInRequired();
          }
          if (error instanceof ClientAuthError) {
            if (error.errorCode === "block_token_requests") {
              throw new InteractiveSignInRequired();
            }
            console.warn("ClientAuthError: error code = ", error.errorCode);
          }
          throw error;
        });
    }
    throw new InteractiveSignInRequired();
  }
}

const authService = new AuthService();
export default authService;
