import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import Tag from "./tag";
import { Language } from "../../enums/languages";
import { Story } from "../../interfaces/story";
import { buildLinkAndClick } from "../../services/story";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    minHeight: "11.25rem",
    background: theme.background.fillWhite,
    boxShadow: "0px 1px 10px 3px rgba(0, 0, 0, 0.1);",
    padding: "0.5rem 1rem 1.25rem 1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    transition:'0.4s ease-out'
  },
  header: {},
  headerTitle: {
    color: theme.textColors.title,
    fontSize: theme.fontSize.header,
    textTransform: "capitalize",
  },
  headerDescription: {
    color: theme.textColors.darker,
    fontWeight: theme.fontWeight.bold,
  },
  footer: { marginTop: "1rem" },
  footerTitle: {
    color: theme.textColors.secondary,
    textTransform: "uppercase",
  },
  footerContent: {
    display: "flex",
    gap: "0.625rem",
    marginTop: "0.625rem",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
interface CardProps {
  story: Story;
}
const Card = (props: CardProps) => {
  const { story } = props;
  const classes = useStyles();

  const onLinkClick = (title: string, language: Language) => {
    buildLinkAndClick(title, language);
  };

  //need to add condition
  const formatHeaderTitle = (title: string) => title.replaceAll("-", " ").replace("s10p", "S10P");

  const availableLangs = Object.entries(Language).map(([key, value]) => ({ key, value }));
  const renderedLanguageTags = availableLangs.map((lang, index) => {
    let unSupportedLang = true;
    let cnCode: any = undefined;
    if (story.languages.includes(lang.value)) {
      unSupportedLang = false;
    }
    if (lang.value === "chinese") {
      cnCode = "cn";
    }
    return (
      <div
        className={classes.link}
        key={`${story.title}${index}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!unSupportedLang) onLinkClick(story.title, lang.value);
        }}
      >
        <Tag
          key={lang.key}
          text={cnCode || lang.value.substring(0, 2)}
          disabled={unSupportedLang}
          value={Language[lang.value]}
        />
      </div>
    );
  });

  return (
    <div className={classes.card} onClick={() => onLinkClick(story.title, Language.english)}>
      <div className={classes.header}>
        <div onClick={(e) =>{e.stopPropagation(); onLinkClick(story.title, Language.english)}} className={classes.link}>
          <div className={classes.headerTitle}>{formatHeaderTitle(story.title)}</div>
        </div>

        <div className={classes.headerDescription}>Duration: {story.duration}min</div>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerTitle}>available in:</div>
        <div className={classes.footerContent}>{renderedLanguageTags}</div>
      </div>
    </div>
  );
};

export default Card;
