interface Env {
  ADMINISTRATION_URL: string;
  STORY_API_URL: string;
  CLIENT_ID: string;
  LANDA_LEARNING_APP_ID: string;
  ADMINISTRATION_APP_ID: string;
  IS_STAGING_SLOT: boolean;
  ENVIRONMENT: string;
  APPINSIGHTS_INSTRUMENTATIONKEY: string;
}

const createEnv = (): Env => {
  const env = (window as any).env;
  return {
    // urls
    ADMINISTRATION_URL: env.APPSETTING_REACT_APP_ADMINISTRATION_URL,
    STORY_API_URL:env.APPSETTING_REACT_APP_STORY_API_URL,

    // client ids
    CLIENT_ID: env.APPSETTING_REACT_APP_CLIENT_ID,
    ADMINISTRATION_APP_ID: env.APPSETTING_REACT_APP_ADMINISTRATION_APP_ID,
    LANDA_LEARNING_APP_ID: env.APPSETTING_REACT_APP_LANDA_LEARNING_APP_ID,
    
    // misc
    IS_STAGING_SLOT: env.APPSETTING_REACT_APP_IS_STAGING_SLOT === "true",
    ENVIRONMENT: env.APPSETTING_REACT_APP_ENVIRONMENT,
    APPINSIGHTS_INSTRUMENTATIONKEY: env.APPSETTING_REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
  } as Env;
};

const env = createEnv();

export default env;
