import Device from "../interfaces/user/device";
import OrganizationBase from "../interfaces/user/organizationBase";
import Role from "../interfaces/user/role";
import { adminFetch } from "../services/auth/authConfig";

const sourceTypeCode='5342'

class AdminAPI {
  fetchOrganizations = async () => {
    const response = await adminFetch(`users/me/organizations?sourceType=${sourceTypeCode}`);
    const organizations: OrganizationBase[] = await response.json();
    return organizations;
  };

  fetchDevices = async () => {
    const response = await adminFetch(`users/me/devices?sourceType=${sourceTypeCode}`);
    const devices: Device[] = await response.json();
    return devices;
  };

  fetchRoles = async () => {
    const response = await adminFetch("users/me/roles");
    const roles: Role[] = await response.json();
    return roles;
  };
}

const adminAPI = new AdminAPI();

export default adminAPI;
