import { Link, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import telemetryService from "../../services/telemetry/telemetryService";
import press from "../../resources/press.svg";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    margin: "38px 0px 0px 49px",
  },
  codeDiv: {
    color: theme.textColors.title,
    fontFamily: "Oswald",
    fontWeight: 650,
    fontSize: "90px",
  },
  mainText: {
    color: theme.textColors.secondary,
    fontFamily: "Oswald",
    fontWeight: 200,
    fontSize: "36px",
    textTransform: "uppercase",
  },
  contentText: {
    color: theme.textColors.secondary,
    fontFamily: "Oswald",
    fontWeight: 200,
    fontSize: "26px",
    textTransform: "capitalize",
    marginTop: "6px",
  },
  img: {
    width: "90%",
    maxWidth: "800px",
    opacity: 0.5,
    marginTop: "73px",
  },
}));

interface ErrorProps {
  code: number;
  error: string;
  onClickSignOut?: () => void;
}

const Error = (props: ErrorProps) => {
  const { error, code, onClickSignOut } = props;
  const classes = useStyles();
  console.error(error);
  const id = telemetryService.trackExceptionData(error);

  return (
    <div className={classes.root}>
      <div className={classes.codeDiv}>{code ? code : 401}</div>
      <div className={classes.mainText}>{error}</div>
      <div className={classes.contentText}>
        Please contact your administrator ({id}).{" "}
        {onClickSignOut ? (
          <>
            <br />
            <Link underline="hover" onClick={onClickSignOut}>
              Sign Out
            </Link>
          </>
        ) : (
          ""
        )}{" "}
      </div>

      <img src={press} alt="press" className={classes.img} />
    </div>
  );
};

export default Error;
