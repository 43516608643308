import { Language } from "../enums/languages";
import env from "../env";
import authService from "./auth/authService";

export const getStorySource = (storyName: string, lang: Language = Language.english, token?: string) => {
  const api = `${env.STORY_API_URL}/stories/`;
  const suffix = `/story-line.html?token=${token}`;
  return api + `${storyName}${lang !== Language.english ? `-${lang}` : ""}` + suffix;
};

export const getAccessToken = async () => {
  const response = await authService.getAccessToken(
    `${env.LANDA_LEARNING_APP_ID}/user_impersonation`
  );
  return response?.accessToken;
};

export const buildLinkAndClick =async (title:string,language:Language,) => {
  let a = document.createElement('a')
  const token = await getAccessToken();
  a.href = getStorySource(title,language,token);
  a.target="_blank"
  a.click()
  a.remove();
}
