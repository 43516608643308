import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const disabledDiagonalBackground =
  "linear-gradient(to top left,rgba(0,0,0,0) 0%,rgba(0,0,0,0) calc(45% - 0.8px),#DCDCDC 42%,#DCDCDC 50%,rgba(0,0,0,0) calc(48% + 0.8px),rgba(0,0,0,0) 100%)";
const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    opacity: 0.9,
    height: "1.5rem",
    width: "2.5rem",
    borderRadius: 2,
    color: theme.textColors.white,
    fontWeight: theme.fontWeight.boldest,
    textTransform: "uppercase",
    padding: "0.063rem 0.313rem",
  },
  disabled: {
    background: disabledDiagonalBackground,
    border: `2px solid ${theme.background.fillLightGray}`,
    color: theme.background.fillLightGray,
    cursor: "default",
  },
}));
interface TagProps {
  text?: string;
  disabled?: boolean;
  value?: any;
}
const Tag = (props: TagProps) => {
  const { text, disabled,value } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.tag} ${disabled ? classes.disabled : ""}`}>
      {text || "Tag"}
    </div>
  );
};
export default Tag;
