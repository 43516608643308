import { useEffect } from "react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import GeneralStore from "../stores/generalStore";
import Section from "./elements/section";
import { Stories } from "../StoriesConfig";
import Navbar from "./navbar";
import Error from "./elements/error";
import Loader from "./elements/loader";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "3.8rem 1.25rem 0 1.25rem",
  },
  title: {
    color: theme.textColors.title,
    fontSize: theme.fontSize.title,
    fontWeight: theme.fontWeight.boldest,
    textTransform: "uppercase",
    marginBottom: "0.93rem",
  },
}));

interface homeProps {
  store: GeneralStore;
  accountInfo: any;
  onSignOut: () => void;
}

const Home = observer((props: homeProps) => {
  const { store, accountInfo, onSignOut } = props;
  const classes = useStyles();

  useEffect(() => {
    store.setUserInfo({
      name: accountInfo.account.name,
      userName: accountInfo.account.userName,
    });
  }, []);

  let subjects = Object.entries(
    store.userStore.devicesTypes ? store.userStore.devicesTypes : []
  ).map(([key, value]) => ({ key, value }));

  const renderedSections = subjects.map((subject, index) => {
    const stories = Stories.filter((story) => story.subject.toString() === subject.value);
    return <Section key={`${subject.value}${index}`} header={subject.value} stories={stories} />;
  });

  return (
    <>
      <Navbar onSignOut={onSignOut!} userInfo={store.userStore.userInfo} />
      {store.userStore.isAllowed ? (
        <div className={classes.container}>
          <div className={classes.title}>Landa Learning Center</div>
          {renderedSections}
        </div>
      ) : store.userStore.isAllowed === undefined ? (
        <Loader />
      ) : (
        <Error code={401} error={"Sorry, you don`t have permission to access this page"} />
      )}
    </>
  );
});
export default Home;
