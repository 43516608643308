import { AuthResponse, Account } from "msal";

/**
 * Encapsulation of the identity of the user.
 */
export default class Identity {
    private account: Account
    private rawIdToken:string
    
    constructor(tokenResponse: AuthResponse) {
      this.account = tokenResponse.account;
      this.rawIdToken = tokenResponse.idToken.rawIdToken;
    }
  
    get userId() {
      return this.account.accountIdentifier;
    }
  
    get emailAddress() {
      return this.account.userName;
    }
  
    get name() {
        return this.account.name;
      }

      get userName() {
        return this.account.userName;
      }
  
    get idToken() {
      return this.rawIdToken;
    }
  }