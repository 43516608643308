import { Button, Card, CardHeader, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UserDetails from "../../interfaces/user/userDetails";
import UserAvatar from "./userAvatar";

const useStyles = makeStyles((theme:Theme) => ({
  fontStyle: {
    fontFamily: "oswald",
    fontWeight: 400,
    fontSize: "0.875rem",
  },
  logoutButton:{
    color: theme.textColors.title,
    fontWeight: theme.fontWeight.boldest,
  },
  card: {
    minWidth: '25rem',
  },
  select: {
    minWidth: '15.625rem',
  },
  currentDiv: {
    display: "flex",
  },
}));

interface UserCardProps {
  photoUrl?: string;
  OrganizationName?: string;
  userInfo: UserDetails;
  logout: () => void;
}

const UserCard = (props: UserCardProps) => {
  const { photoUrl, OrganizationName, userInfo, logout } = props;
  const classes = useStyles();

  const renderCurrent = () => {
    return <div className={classes.currentDiv}>{OrganizationName}</div>;
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<UserAvatar photoUrl={photoUrl} name={userInfo.name} />}
        action={
          <Button size="small" onClick={() => logout()} className={`${classes.logoutButton} ${classes.fontStyle}`}>
            Logout
          </Button>
        }
        classes={{title:classes.fontStyle}}
        title={userInfo.userName}
        subheader={OrganizationName ? renderCurrent() : ""}
      />
    </Card>
  );
};

export default UserCard;
