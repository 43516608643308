import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

export interface UserAvatarProps {
  photoUrl: string | undefined;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '4.625rem',
    height: '4.625rem',
    fontSize: '2rem',
  },
}));

const UserAvatar = (props: UserAvatarProps) => {
  const { photoUrl, name } = props;
  const classes = useStyles();

  return photoUrl ? (
    <Avatar src={photoUrl} />
  ) : name && name.length > 0 ? (
    <Avatar className={classes.avatar}>{getInitials(name)}</Avatar>
  ) : (
    <Avatar className={classes.avatar}>??</Avatar>
  );
};

const getInitials = (name: string) => {
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  } else {
    initials += names[0].length > 1 ? names[0].substring(1, 1).toUpperCase() : "";
  }

  return initials;
};

export default UserAvatar;
