import { ApplicationInsights, ITelemetryItem, SeverityLevel, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import history from '../../history';
import {v4 as uuidv4} from 'uuid';
import env from '../../env';

const ID = () => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  };

class TelemetryService {
    public reactPlugin: ITelemetryPlugin
    appInsights?: ApplicationInsights
    username: string = ""


    constructor() {
        this.reactPlugin = new ReactPlugin() as ITelemetryPlugin;
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: env.APPINSIGHTS_INSTRUMENTATIONKEY,
                disableFetchTracking: false,
                extensions: [this.reactPlugin],
                enableAutoRouteTracking: true,
                enableDebug: true,
                extensionConfig: {
                    [this.reactPlugin.identifier]: { history: history }
                  }
            }
        });

        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer(this.telemetryInitializer)
    }

    telemetryInitializer = (envelope: ITelemetryItem) => {
        if (!envelope || !envelope.tags) return

        envelope.tags["ai.cloud.role"] = "Landa Learning Frontend"
        envelope.tags["ai.user.authenticatedUserId"] = this.username
        this.appInsights && this.appInsights.setAuthenticatedUserContext(this.username)
      }
    
    public setUser = (username: string) => {
        this.username = username

        if (!this.appInsights) return

        this.appInsights.context.user.authenticatedId = this.username
        this.appInsights.setAuthenticatedUserContext(this.username)
    }

    trackEvent = (eventName: string, properties?: { [key: string]: any; }, measurements?: { [key: string]: number; }) => {
        this.appInsights && this.appInsights.trackEvent({ name: eventName, properties: properties, measurements: measurements })
    }

    trackDependencyData = (url: string, method:string) => {
        this.appInsights && this.appInsights.trackDependencyData({id: ID(), name:url, data:url, responseCode:200})
    }

    trackExceptionData = (error:string) => {
        const id = uuidv4()
        this.appInsights && this.appInsights.trackException({id: id, exception:new Error(error), severityLevel:SeverityLevel.Error, properties:{'correlationId':id}})
        return id
    }

    trackExceptionDataV2 = (error:any) => {
        const id = uuidv4()
        this.appInsights && this.appInsights.trackException({id: id, exception:new Error(error), severityLevel:SeverityLevel.Error, properties:{'correlationId':id}})
        return id
    }
}

const telemetryService = new TelemetryService()
export default telemetryService