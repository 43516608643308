import { CircularProgress } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const theme = createTheme();
const useStyles = makeStyles((theme: any) => ({
  container: {
    zIndex: 1005,
    backgroundColor: "#ffffff",
    width: "100%",
    height: "100%",
    opacity: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const Loader = (props: any) => {
  const classes = useStyles(theme);
  return (
    <div className={classes.container}>
      <CircularProgress />
      {props.withLabel && <div>{props.label || "Loading..."}</div>}
    </div>
  );
};
export default Loader;
