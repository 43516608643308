import { IconButton, Popover, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import logo from "../resources/logo.svg";
import UserDetails from "../interfaces/user/userDetails";
import UserCard from "./elements/userCard";

const useStyles = makeStyles((theme: Theme) => ({
  navbarContainer: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "2.5rem",
    maxHeight: "2.5rem",
    background: theme.background.fillWhite,
    zIndex: 99,
    boxShadow: "0px 1px 10px 3px rgba(0, 0, 0, 0.1);",
  },
  logo: {
    width: "4.601rem",
    marginLeft: "1.25rem",
  },
  logoutButton: {
    marginRight: "1rem",
  },
  iconPrimaryButton: {
    width: 30,
    marginRight:15,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconPrimary: {
    color: theme.palette.primary.main,
    width: 30,
  },
  popup: {
    zIndex: theme.zIndex.drawer + 2000,
  },
}));

interface NavbarProps {
  onSignOut: () => void;
  userInfo?: UserDetails;
  organizationName?: string;
}

const Navbar = (props: NavbarProps) => {
  const { onSignOut, userInfo } = props;
  const classes = useStyles();
  const [open,setOpen] = useState(false);
  const id = open ? "menu-appbar" : undefined;

  const handleCloseUserProfile = () => setOpen(false)
  const handleOpenUserProfile = () =>setOpen(true);

  return (
    <div className={classes.navbarContainer}>
      <img className={classes.logo} src={logo} alt="logo" />
      <IconButton
        className={classes.iconPrimaryButton}
        aria-describedby={id}
        onClick={handleOpenUserProfile}
      >
        <FontAwesomeIcon icon={faUserCircle} className={classes.iconPrimary} />
      </IconButton>
      {userInfo && (
        <Popover
          className={classes.popup}
          id={id}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 70, left: 5000 }}
          open={open}
          onClose={handleCloseUserProfile}
        >
          <div className={classes.popup}>
            <UserCard userInfo={userInfo} logout={onSignOut} />
          </div>
        </Popover>
      )}
    </div>
  );
};

export default Navbar;
