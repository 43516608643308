import { createTheme } from "@mui/material";
import Brushes from "./brushes";

declare module "@mui/material/styles" {
  interface CustomTheme {
    textColors: {
      title: string;
      primary: string;
      secondary: string;
      darker: string;
      fail: string;
      white: string;
    };
    fontSize: {
      header: string;
      subTitle: string;
      title: string;
    };
    fontWeight: {
      regular: number,
      bold: number,
      boldest: number,
    },
    background:{
      fillWhite : string,
      fillLightGray:string
    }
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export const appTheme = createTheme({
  textColors: {
    title: Brushes.mainPrimary,
    primary: Brushes.textPrimary,
    secondary: Brushes.textSecondary,
    darker: Brushes.textDarker,
    fail: Brushes.textFail,
    white: Brushes.textWhite,
  },
  fontSize: {
    header: "1.68rem",
    subTitle: "1.875rem",
    title: "2.5rem",
  },
  fontWeight: {
    regular: 200,
    bold: 300,
    boldest: 500,
  },
  background:{
    fillWhite:Brushes.fillWhite,
    fillLightGray:Brushes.fillLight
  },
  palette: {
    primary: {
      main: Brushes.mainPrimary,
    },
    secondary: {
      main: Brushes.mainSecondary,
    },
    error: {
      main: Brushes.mainFail,
    },
    success: {
      main: Brushes.mainSuccess,
    },
    warning: {
      main: Brushes.mainWarning,
    },
    common: {
      white: Brushes.fillWhite,
    },
  },
  typography: {
    fontFamily: [
      "Oswald",
      "Oswald-Regular_ExtraLight",
      "Oswald ExtraLight",
      "Roboto",
      "Roboto-Regular",
    ].join(","),
  },
});
