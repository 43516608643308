import { configure, observable, action } from "mobx";
import UserDetails from "../interfaces/user/userDetails";
import { AdministrationStore } from "./administrationStore";
import UserStore from "./userStore";

configure({ enforceActions: "observed" });
export default class GeneralStore {
  @observable userStore: UserStore;
  @observable administrationStore: AdministrationStore;

  constructor() {
    this.userStore = new UserStore();
    this.administrationStore = new AdministrationStore();
  }

  @action 
  setUserInfo = (userInfo:UserDetails) =>{
      this.userStore.setUserInfo(userInfo);
      this.administrationStore.setUserInfo(userInfo);
  }
  
}
