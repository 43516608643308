import env from "../../env";
import authService from "./authService";

var environment: "prod" | "mock" = "prod";

export const setServerEnv = (env: "prod" | "mock") => (environment = env);

const fetchData = (url: string,scope: string, options: any = {}): Promise<any> => {
  return authService.request(url, scope, options);
};

// const adminServerUrl = 'https://localhost:44391/api/v1/'
// const adminServerUrl = 'http://localhost:5000/api/v1/'
const adminServerUrl =  env.ADMINISTRATION_URL + "/api/v1/";
export const adminFetch = (url: string, options: any = {}) => {
  const scope: string = env.ADMINISTRATION_APP_ID + "/user_impersonation";
  return fetchData(adminServerUrl + url, scope, options)
};

const graphApi = "https://graph.microsoft.com";
export const graphFetch = (fetch: (input: string, init: any) => Promise<any>,url: string, options: any) => {
  const scope: string = graphApi + "/User.Read";
  return fetchData(url, scope, options);
};

export class InteractiveSignInRequired extends Error {
  constructor() {
    super();
    this.name = "InteractiveSignInRequired";
  }
}

export class LoginFailed extends Error {
  constructor(message: string) {
    super(message);
    this.name = "LoginFailed";
  }
}

export class TokenRequestInHiddenIFrame extends Error {
  constructor() {
    super();
    this.name = "TokenRequestInHiddenIFrame";
  }
}
