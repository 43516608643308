import { UserAgentApplication, Logger, LogLevel, AuthenticationParameters, Configuration, WindowUtils } from "msal"
import env from "../../env"

export const requiresInteraction = (errorMessage: any) => {
    if (!errorMessage || !errorMessage.length) {
    return false
    }

    return (
    errorMessage.indexOf("consent_required") > -1 ||
    errorMessage.indexOf("interaction_required") > -1 ||
    errorMessage.indexOf("login_required") > -1
    )
}

export const fetchMsGraph = async (url:any, accessToken:string) => {
    const response = await fetch(url, {
        headers: {
        Authorization: `Bearer ${accessToken}`
        }
    })

    return response.json()
}

export const fetchWebApi = async (url: string, options: any, scope: string, accessToken:string, signal:AbortSignal) => {

    if (WindowUtils.isInIframe()) return

    const accessTokenRequest: AuthenticationParameters = {
        scopes: [scope]
    }

    const prom = fetch(url, {
        method: options ? options.method : 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        body: options && options.body,
        signal: signal
    }).catch(r => {
        console.error('[AUTH]', r)
    })

    return prom
}

const graphApi = "https://graph.microsoft.com"

const globalScopes = [graphApi + '/User.Read']

const extraGlobalScopes = [
    env.ADMINISTRATION_APP_ID + '/user_impersonation'
]

const signInOptions: AuthenticationParameters = {
    scopes: globalScopes,
    extraScopesToConsent: extraGlobalScopes,
    prompt: 'select_account'
}

const msalLogger = new Logger(
    (logLevel, message, containsPii) => {
        console.log("[MSAL]", message)
    },
    {
        level: LogLevel.Error,
        piiLoggingEnabled: false
    }
)

const msalConfig: Configuration = {
    auth: {
        authority: 'https://login.microsoftonline.com/insightlanda.onmicrosoft.com',
        clientId: env.CLIENT_ID||"",
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    },
    system: {
        navigateFrameWait: 500,
        logger: msalLogger,
    }
}

export const GRAPH_SCOPES = {
    OPENID: "openid",
    USER_READ: "User.Read",
}

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    PHOTO: "https://graph.microsoft.com/v1.0/me/photo/$value"
}

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.USER_READ],
        prompt: 'select_account'
    },
}

export const msalApp = new UserAgentApplication(msalConfig)
