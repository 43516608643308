import { makeStyles, ThemeProvider } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import withAuth from "./hocs/AuthHoc";
import telemetryService from "./services/telemetry/telemetryService";
import Error from "./components/elements/error";
import Welcome from "./components/welcome";
import Loader from "./components/elements/loader";
import Home from "./components/home";
import { appTheme } from "./styles/appTheme";
import GeneralStore from "./stores/generalStore";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
  appContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: 0,
  },
}));

export interface AppProps {
  account?: any;
  error?: any;
  inProgress?: boolean;
  onSignIn?: () => void;
  onSignOut?: () => void;
}

const App = observer((props: AppProps) => {
  telemetryService.trackEvent("App Loaded");
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <div className={classes.appContainer}>
          {props.account ? (
            <Home accountInfo={props.account} onSignOut={props.onSignOut!} store={new GeneralStore()} />
          ) : (
            !props.account &&
            (props.error ? (
              <Error
                error={
                  props.error ? props.error : "Sorry, you don`t have permission to access this page"
                }
                code={401}
                onClickSignOut={props.onSignOut!}
              />
            ) : props.inProgress ? (
              <Loader />
            ) : (
              <Welcome onSignIn={props.onSignIn!} />
            ))
          )}
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default withAuth(App);
