import { Language } from "./enums/languages";
import { Subject } from "./enums/subjects";
import { Story } from "./interfaces/story";

const commonLangs = [Language.english, Language.chinese, Language.french, Language.spanish];

export const Stories: Story[] = [
  { title: "nanography", languages: commonLangs, duration: 30, subject: Subject.General },
  { title: "landa-s10-introduction", languages: commonLangs, duration: 15, subject: Subject.S10 },
  { title: "landa-s10-safety", languages: [Language.english],duration:25,subject:Subject.S10 },
  { title: "landa-interface-application", languages: commonLangs,duration:25,subject:Subject.General },
  { title: "printing-system", languages: commonLangs,duration:25,subject:Subject.General },
  { title: "substrate-transport-system", languages: commonLangs,duration:30,subject:Subject.General },
  { title: "active-quality-management", languages: commonLangs,duration:25,subject:Subject.General },
  { title: "landa-s10-blanket-conveyor-system", languages: [Language.english],duration:15,subject:Subject.S10},
  { title: "match-to-offset", languages: [Language.english] ,duration:10,subject:Subject.General},
  { title: "support-systems", languages: commonLangs,duration:25,subject:Subject.General },
  { title: "landa-s10p-introduction", languages: [Language.english, Language.french],duration:15,subject:Subject.S10P},
  { title: "landa-s10p-safety", languages: [Language.english],duration:25,subject:Subject.S10P },
  { title: "landa-s10p-blanket-conveyor-system",languages: [Language.english],duration:15,subject:Subject.S10P},
  { title: "landa-dfe-overview", languages: commonLangs,duration:'16:01',subject:Subject.PrePress },
  { title: "managing-the-landa-dfe", languages: commonLangs ,duration:'15:21',subject:Subject.PrePress},
  { title: "landa-dfe-device-center", languages: commonLangs,duration:'19:37',subject:Subject.PrePress },
  { title: "dfe-die-line-control", languages: commonLangs,duration:'12:24',subject:Subject.PrePress },
  { title: "landa-dfe-versioning", languages: commonLangs,duration:'09:39',subject:Subject.PrePress },
  //spot on is missing!!
];

