enum Brushes {
  mainPrimary = "#00AEEF",
  mainSecondary = "#9352C6",
  mainSuccess = "#8EB224",
  mainFail = "#F95165",
  mainWarning = "#FFB21D",
  textDarker = "#333333",
  textPrimary = "#4F4F4F",
  textSecondary = "#797979",
  textFail = "#F95165",
  textWhite = "#FFFFFF",
  fillWhite = "#ffffff",
  border = "#D7D7D7",
  fillLight = "#DCDCDC",
}

export default Brushes;
