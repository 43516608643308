import React from "react";
import { AuthError, ClientAuthError } from "msal";
import telemetryService from "../services/telemetry/telemetryService";
import authService from "../services/auth/authService";
import Identity from "../models/identity";
import { msalApp } from "../services/auth/authUtils";
import { AppProps } from "../App";

const withAuth = (Component: React.ComponentType<AppProps>) =>
  class extends React.Component<AppProps> {
    constructor(props: AppProps) {
      super(props);

      this.state = {
        account: null,
        error: null,
        emailMessages: null,
        graphProfile: null,
        inProgress: null,
      };
    }

    private setAuthError(error: AuthError) {
      if (!error) return;
      if (error instanceof ClientAuthError) {
        if (error.errorCode === "popup_window_error") {
        }
        // ignore
        return;
      }
      telemetryService.trackExceptionDataV2(error);
      const errorMessage = error.errorMessage ? error.errorMessage : "Unable to login.";
      this.setState({ error: errorMessage });
    }

    async onSignIn() {
      this.setState({ error: null });

      authService.handleIdentityCallback(this.onIdentity);
      await authService.signIn().catch((error) => {
        this.setAuthError(error);
      });
    }

    private onIdentity = (identity: Identity) => {
      this.setState({ account: identity });
    };

    onSignOut() {
      authService.signOut();
    }

    async componentDidMount() {
      this.setState({ inProgress: true });

      msalApp.handleRedirectCallback((error) => {
        this.setAuthError(error);
      });

      const account = await authService.getIdentity().catch((error) => {
        //should be fixed in authService
        //console.log(error);
      });
      this.setState({ account, inProgress: false });
    }

    render() {
      const myState: any = this.state;
      return (
        <Component
          {...this.props}
          account={myState.account}
          error={myState.error}
          onSignIn={() => this.onSignIn()}
          onSignOut={() => this.onSignOut()}
          inProgress={myState.inProgress}
        />
      );
    }
  };

export default withAuth;
