import { createBrowserHistory } from "history";

const history = createBrowserHistory({});

const appHistory = {
    ...history,
    push(path: string, state?: unknown) {
        if ((history.location.pathname === path) && (history.location.state === state)) {
            history.replace(path, state)
        }
        else{
            history.push(path, state)
        }
    }
}

export default appHistory;